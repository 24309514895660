<template lang="">
  <section class=" ">
    <div v-if="loginUserData && loginUserData.type == 'lawyer'">
      <LawyerHeader />
    </div>
    <div v-else>
      <GeneralHeader />
    </div>

    <div class="WordSection1 container pt-3">
      <h3 class="mt-3 text-center">Terms and Conditions for Lawyers</h3>

      <h6>
        1<span>&nbsp;&nbsp;&nbsp;</span>Introduction
      </h6>
      <p>
        1.1
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>These
        terms and conditions (<b>Terms</b>) govern your use of the website
        ‘makinglawsimple.com.au’ (<b>Website</b>) as a Lawyer (defined in clause
        1.2(b) of these Terms). The Website is operated by Simplawfy Pty Ltd
        (<b>Simplawfy</b>). By using the Website as a Lawyer, you acknowledge
        that you have read, understood and agree to be bound by the Terms.
      </p>

      <p>
        1.2
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>The
        Website provides a platform (<b>Platform</b>) that facilitates the
        exchange of:
      </p>
      <p>
        (a)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
        >requests for proposals for legal services (known as jobs) from
        potential clients, who register through the ‘I’m looking for a
        lawyer/Sign up to find a lawyer’ portal (<b>Clients</b>); and
      </p>
      <p>
        (b)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
        >proposals for legal services from lawyers, who register through the
        ‘I’m looking for clients/Sign up to find clients’ portal
        (<b>Lawyers</b>).
      </p>
      <p>
        1.3
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>In
        order to access the Platform as a Lawyer and submit proposals, you must
        hold an account on the Website (<b>Account</b>), be verified by
        Simplawfy (<b>Verification</b>) and have an active ‘Connect’ membership
        subscription to the Platform (<b>Membership Subscription</b>).
      </p>

      <h6>
        2<span>&nbsp;&nbsp;&nbsp;</span>Account
        and profile
      </h6>
      <p>
        2.1
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>To
        create an Account, you will be required to complete a profile involving
        providing some personal and contact details, and information about you
        and your law firm to Simplawfy.
      </p>

      <p>
        2.2
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>Whilst you hold an Account, you warrant that:
      </p>
      <p>
        (a)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>all
        information you provide through the Platform is accurate, complete and
        current;
      </p>
      <p>
        (b)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>if
        any information changes in relation to your Account (including your
        profile), you will immediately update it accordingly;
      </p>
      <p>
        (c)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>you
        are an Australian legal practitioner holding a current, unrestricted
        practicing certificate valid for private law practice in at least one
        jurisdiction in Australia;
      </p>
      <p>
        (d)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        you have the legal authority, qualifications, certifications, skills,
        expertise, capacity and resources to offer and competently provide legal
        services to Clients on the Platform, in the areas of practice and
        jurisdictions you have represented you practice in;
      </p>
      <p>
        (e)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        you or the law practice you work for holds a current professional
        indemnity insurance policy with coverage that is adequate and
        appropriate to the legal services you carry out;
      </p>
      <p>
        (f)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        you have the right, authority and capacity to enter into legally binding
        contracts including agreeing to these Terms;
      </p>
      <p>
        (g)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        if you are creating an Account on behalf of a business (including a
        company), you are duly authorised to bind the business, you act as an
        agent for and on behalf of that business for the purposes of these Terms
        and your use of the Website and Platform, and that business is taken to
        have accepted the Terms; and
      </p>
      <p>
        (h)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        if any of the warranties referred to in this clause 2.2 become untrue,
        you will notify Simplawfy immediately.
      </p>

      <p>
        2.3
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
        >Whilst you hold an Account, you must not:
      </p>
      <p>
        (a)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>share
        access to or details (including passwords) of your Account with any
        other person, nor transfer or assign your Account, except with the
        express written consent of Simplawfy;
      </p>
      <p>
        (b)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>use
        the Account of another Lawyer, except with the express written consent
        of Simplawfy;
      </p>
      <p>
        (c)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
        >provide legal advice, services or opinions directly through the
        Platform; or
      </p>
      <p>
        (d)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        breach the confidentiality of any information from and communications
        with Clients made through the Platform.
      </p>

      <p>
        2.4
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>You
        are responsible for maintaining the security of your Account and for all
        activities that occur under your Account. You must notify Simplawfy
        immediately of any suspected or actual unauthorised use or any other
        breach of confidentiality or compromise of security in relation to your
        Account. Simplawfy will not be liable for any loss, damage or liability
        arising from the unauthorised use of your Account or computing device.
      </p>

      <p>
        2.5
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
        >Simplawfy may suspend your Account (and thereby your access to the
        Platform) by written notice and with immediate effect if:
      </p>

      <p>
        (a)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>you
        breach any of these Terms including any of the warranties in clause 2.2
        or any of the negative covenants in clause 2.3, or if Simplawfy has a
        reasonable basis to suspect that you may have done so, whilst an
        investigation is conducted;
      </p>
      <p>
        (b)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>you
        have notified Simplawfy of a breach or compromise of the security of
        your Account under clause 2.4;
      </p>
      <p>
        (c)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>your
        Verification has been unsuccessful (see clause 3.4); or
      </p>
      <p>
        (d)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Simplawfy considers that you are no longer suitable to continue as a
        member of the Platform as a Lawyer, such as being the subject of an
        unreasonable number of low ratings, poor reviews or complaints by
        Clients.
      </p>

      <p>
        2.6
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        You may delete your Account by clicking ‘Delete Account’ on the Account
        page. The deletion of an Account will result in the immediate
        cancellation of any active Membership Subscription. If you wish to use
        the Platform again in future, you will need to create a new Account.
      </p>

      <h6>
        3<span>&nbsp;&nbsp;&nbsp;</span>Verification
      </h6>

      <p>
        3.1
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Once you have completed creating your Account and profile, Simplawfy
        will conduct Verification by reviewing the profile information provided
        under clause 2.1 to:
      </p>

      <p>
        (a)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        verify, to the best of its ability and using publicly available
        information, that you are an Australian legal practitioner, as required
        by clause 2.2(c); and
      </p>
      <p>
        (b)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        determine, in its sole discretion, that you are otherwise suitable to be
        a member of the Platform as a Lawyer.
      </p>

      <p>
        3.2
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        As part of Verification, Simplawfy may request the provision of further
        information from you that it considers reasonably necessary, or suggest
        changes that should be made to your profile that may make it suitable
        for approval, if necessary.
      </p>

      <p>
        3.3
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Following completion of Verification, Simplawfy will notify you of
        whether or not your Verification has been successful and, if
        unsuccessful, include reasons why.
      </p>
      <p>
        3.4
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        If your Verification has been unsuccessful, your Account will be
        suspended. You agree not to create a new Account unless you are able in
        future to resolve the reasons for your rejection.
      </p>

      <p>
        3.5
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        If you change your name or states/territories that you practice in on
        your profile, you will be required to pass Verification again (in
        respect of that change) before the change will be reflected, to ensure
        that you remain listed as an Australian legal practitioner under the new
        name or in the new jurisdiction (as the case may be).
      </p>
      <p>
        3.6
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Simplawfy may conduct ongoing, periodic checks to ensure it is satisfied
        of your continued satisfaction of the matters listed in clauses 3.1(a)
        and 3.1(b).
      </p>
      <p>
        3.7
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Simplawfy’s decision regarding Verification is final. Simplawfy is under
        no obligation to approve your profile and is not liable for any decision
        in relation to Verification.
      </p>

      <h6>
        4<span>&nbsp;&nbsp;&nbsp;</span>Membership
        Subscription
      </h6>

      <p>
        4.1
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Lawyers whose Verification has been successful must have an active
        Membership Subscription in order to access the Platform, which will
        require the Lawyer to provide details of a valid credit card, from which
        the applicable membership fee (the current amount of which will be
        posted on the Website) (<b>Membership Fee</b>) will be direct debited
        recurring monthly in advance unless the Membership Subscription is
        suspended or until cancelled pursuant to clauses 4.3 or 4.4,
        respectively.
      </p>

      <p>
        4.2
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Notwithstanding clause 4.1, each Lawyer may be entitled to a one-off
        60-day free trial period (<b>Trial</b>) commencing on the date of
        Membership Subscription, during which time the Lawyer may have access to
        and use the Platform without charge for their initial 60 days. The
        Lawyer will still be required to provide their credit card details in
        order to access the Trial. The Membership Fee will start to be charged
        at the conclusion of the Trial in accordance with clause 4.1.
      </p>

      <p>
        4.3
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Your Membership Subscription may be suspended if payment has not been
        received at the requisite time, due to your credit card payment being
        unable to be processed (for example, due to it being expired or
        cancelled). Your Membership Subscription will be reactivated once you
        have updated your credit card details and the requisite payment has been
        processed.
      </p>

      <p>
        4.4
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        You may cancel an active Membership Subscription at any time, at which
        time your Membership Subscription will be cancelled from the end of the
        current billing period. You may resubscribe any cancelled Membership
        Subscription in future, provided you still have an active Account.
      </p>

      <p>
        4.5
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        You acknowledge that the Membership Fee only entitles access to the
        Platform on a monthly basis and is not predicated on or a guarantee of
        the generation of any business through the Platform. The Membership Fee
        is not a commission, finder’s fee, success fee or similar. No amount of
        Membership Fee will be refunded to a Lawyer.
      </p>

      <h6>
        5<span>&nbsp;&nbsp;&nbsp;</span>Viewing
        jobs and submitting proposals
      </h6>

      <p>
        5.1
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Clients may submit jobs through the Platform. Once posted, jobs may be
        visible to Lawyers on the Platform who hold an active Membership
        Subscription and practice in a matching practice area and jurisdiction
        to the job.
      </p>

      <p>
        5.1
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        In response to a visible job, a Lawyer may submit a proposal for the
        job, in which case the following applies:
      </p>
      <p>
        (a)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        you must specify a cost estimate (unless pro bono) expressed as a
        particular charging method, any estimated disbursements and any upfront
        payment required. Your cost estimate must be accurate and properly
        reflect the total cost the Client will be liable to pay should they
        accept your proposal, based on the Client’s description of the job and
        any further information obtained through messaging communications
        between you and Client. If your cost estimate is qualified or dependent
        upon certain assumptions or contingencies, you should fully disclose and
        explain this in the proposal. You should exercise the same care and
        diligence in providing cost estimates for proposals through the Platform
        as you would in your usual practice, in particular to avoid
        underestimating or otherwise misleading or deceiving Clients;
      </p>
      <p>
        (b)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        you must specify the scope of proposed work, properly identifying all
        the work you propose to perform for the specified cost estimate;
      </p>
      <p>
        (c)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        if your proposal indicates that the Client is required to pay any amount
        upfront, you warrant that you or your legal practice possesses a trust
        account and will hold the upfront payment in trust in accordance with
        relevant regulatory requirements;
      </p>
      <p>
        (d)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        the Client’s posting of a job does not form an invitation to treat,
        offer or binding commitment — the Client has the ultimate discretion to
        accept the terms of a Lawyer’s proposal; and
      </p>
      <p>
        (e)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        a proposal does not constitute an offer or acceptance of a contract, and
        neither a contract nor a lawyer/client relationship is formed between
        you and any Client, until and unless you have been formally engaged by
        the Client (see paragraph 5.8).
      </p>
      <p>
        5.3
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Lawyers may also receive or send messages to a Client through the
        Platform, in which case the following applies:
      </p>

      <p>
        (a)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        the messaging facility is provided for the sole purpose of allowing
        either a Lawyer or Client to seek further information or clarification,
        in order for a Lawyer to submit a proposal or a Client to consider a
        Lawyer’s submitted proposal;
      </p>
      <p>
        (b)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Lawyers may only send 1 message to Clients initially, but will be able
        to communicate freely if the Client responds;
      </p>
      <p>
        (c)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        you must not use the messaging facility to spam Clients with an
        unnecessary or unreasonable volume of messages;
      </p>
      <p>
        (d)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        you must not use the messaging facility as a means of giving advice
        directly or to bypass the proper proposal submission and acceptance
        process;
      </p>
      <p>
        (e)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        you are responsible for the content and accuracy of any information you
        communicate to the Client through any messages; and
      </p>
      <p>
        (f)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        once sufficient information is obtained, you may submit a proposal, in
        which case clause 5.2 applies.
      </p>
      <p>
        5.4
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Lawyers may also choose to reject any visible jobs that they consider
        unsuitable.
      </p>
      <p>
        5.5
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Lawyers may withdraw a submitted proposal prior to the Client’s
        acceptance, for instance if they are no longer in a position to honour
        the terms of it. Lawyers may not submit a new proposal for the same job,
        except as permitted by Simplawfy.
      </p>
      <p>
        5.6
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Clients may cancel a job prior to accepting a Lawyer’s proposal, for
        instance if it is no longer required.
      </p>
      <p>
        5.7
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        If a Client accepts your proposal (<b>Accepting Client</b>), the
        proposal process and any messaging will be closed. Your contact details
        and the contact details of the Accepting Client will be exchanged, to
        facilitate your continuation of the legal matter directly with the
        Accepting Client. All further communications between you and the
        Accepting Client must be made off the Platform and you will be unable to
        continue to correspond through the Platform in relation to that job.
      </p>

      <p>
        5.8
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        In respect of an Accepting Client, you agree and acknowledge that a
        proposal does not constitute an offer or acceptance of a contract, and
        neither a contract nor a lawyer/client relationship is formed between
        you and any Client (including an Accepting Client), unless and until you
        have been formally engaged by that Accepting Client. You are responsible
        for all aspects of the subsequent legal engagement with the Accepting
        Client, as if the Accepting Client had sought your services directly,
        including but not limited to liaising with the Accepting Client;
        conducting conflict checks; issuing an engagement letter, costs
        agreement and disclosure (in accordance with the terms of the accepted
        proposal); receipting any upfront payments into trust; receiving further
        instructions, information or evidence; performing the agreed work;
        managing the conduct of the matter, and the relationship and
        communication with the Accepting Client; ensuring adequate resources and
        capacity to perform the work; negotiating any additional work; billing,
        issuing invoices to and collecting payment (including taking any
        necessary recovery action) of any legal fees, costs and disbursements
        from the Accepting Client; handling of any complaints regarding the
        work, fees, conduct or any other matter; closing the matter; retaining
        and storing documents; and complying with all applicable professional
        obligations, requirements and regulations.
      </p>
      <p>
        5.9
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        You acknowledge that, following your engagement, the Accepting Client
        may leave a review and rating in respect of the services rendered by
        you. Simplawfy is not responsible for any reviews and ratings left by
        Accepting Clients. If you wish to appeal a review or rating left by an
        Accepting Client on the basis that you consider it unfair or inaccurate,
        you must notify Simplawfy as soon as possible. Simplawfy may, at its
        absolute discretion, change, alter or remove the rating and/or review if
        it considers it reasonable to do so, but is not liable for doing or
        failing to do so.
      </p>
      <p>
        5.10
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Simplawfy makes no warranty, representation or guarantee in respect of:
      </p>
      <p>
        (a)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        the number, type, suitability or legitimacy of Clients that will use the
        Platform;
      </p>
      <p>
        (b)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        the number, type, suitability or legitimacy of any jobs that will be
        made available to the Lawyer through the Platform, including correct
        classification of the job’s practice area;
      </p>
      <p>
        (c)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        the truthfulness, completeness or accuracy of any information provided
        by the Client through the Platform;
      </p>
      <p>
        (d)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        whether Clients will accept or respond to a Lawyer’s proposal or message
        (the Client’s decision to accept or reject a Lawyer’s proposal is final,
        and Simplawfy is not responsible for the choices made by Clients); or
      </p>
      <p>
        (e)
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        any aspect of the relationship between the Lawyer and the Accepting
        Client including, without limitation, that the Client will proceed with
        the engagement, or will pay any fees, disbursements or costs due.
      </p>

      <h6>
        6<span>&nbsp;&nbsp;&nbsp;</span>Dispute
        resolution
      </h6>
      <p>
        6.1
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        In the event a dispute arises between the Lawyer and Simplawfy in
        relation to the Platform or these Terms, the parties agree to deal with
        the dispute in accordance with the provisions of this clause 6 Dispute
        resolution.
      </p>
      <p>
        6.2
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        A party claiming a dispute has arisen must, prior to instituting legal
        action, give written notice to the other party particularising the
        nature of the dispute.
      </p>
      <p>
        6.3
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Upon receipt of the notice referred to in clause 6.2, the parties must
        meet within 7 days and endeavour in good faith to resolve the dispute.
      </p>
      <p>
        6.4
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        If, after the meeting referred to in clause 6.3 is held, the dispute
        remains unresolved, the parties must agree upon a mediator to be
        appointed (and where the parties cannot agree, a mediator is to be
        appointed by the President of the Law Society or Law Institute in the
        state or territory where the Lawyer is situated), and attend a mediation
        conducted by that mediator (which may be conducted virtually), with the
        parties to bear equally the costs of the mediator and mediation, and to
        bear their own costs.
      </p>
      <p>
        6.5
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        If, after the mediation referred to in clause 6.4 is held, the dispute
        remains unresolved, either party may institute legal proceedings
        concerning the dispute.
      </p>

      <p>
        6.6
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        All communications concerning negotiations made by the parties in the
        course of attempting to resolve the dispute using this clause 6 Dispute
        resolution are to be treated as confidential and without prejudice.
      </p>

      <h6>
        7<span>&nbsp;&nbsp;&nbsp;</span>Limitation
        of liability
      </h6>
      <p>
        7.1
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        The Platform provided by Simplawfy is a platform to facilitate the
        connecting of Clients and Lawyers only. Simplawfy is not a lawyer, law
        firm, legal practice, list or panel of lawyers, employment agency, or
        legal professional referral service, and does not engage in legal
        practice itself. Simplawfy does not select, recommend, approve or
        endorse any particular Lawyer or Client. Nothing in the Website or
        Platform is to be construed as an offer or provision of legal advice,
        services, opinions or representation given by Simplawfy or its
        affiliates, officers, agents or employees, or the creation of any
        lawyer/client relationship.
      </p>
      <p>
        7.2
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Simplawfy is not a contractual party to, is not involved in, and will
        not have any liability with respect to, any work, legal matter,
        engagement or communications between Clients and Lawyers, whether or not
        made through the Platform. Lawyers are independent of Simplawfy and are
        not employees or consultants of Simplawfy. Any claims between Clients
        and Lawyers arising out of the legal advice, services, opinions or
        representation provided by Lawyers or their law practice, legal fees,
        disbursements or costs payable by Clients, or any other matter, is to be
        brought against the Lawyer or Client individually and not Simplawfy. You
        indemnify Simplawfy from all claims, suits, demands and legal actions
        arising from any and all disputes between a Client and you as a Lawyer.
      </p>
      <p>
        7.3
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        While Simplawfy uses commercially reasonable efforts to verify that the
        Lawyers on the Platform are registered Australian legal practitioners at
        their time of approval to use the Platform, it does not make any
        warranty, representation or guarantee as to the legitimacy,
        qualifications, certifications, legal ability, competence, quality,
        fitness for purpose, insurance coverage or potentially achievable
        results of any Lawyer. Simplawfy makes no warranty, representation or
        guarantee in relation to any Client. Simplawfy encourages Clients and
        Lawyers to make all necessary enquiries to satisfy themselves as to the
        suitability of the counterparty.
      </p>
      <p>
        7.4
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        To the fullest extent permitted by law, Simplawfy excludes all
        representations, warranties or guarantees, whether express or implied,
        by statute, trade or otherwise, as to the operation of the Website or
        the contents of it, including the Platform.
      </p>
      <p>
        7.5
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Any job details, proposals and messages made via the Platform may not be
        confidential or protected by legal professional privilege. Lawyers and
        Clients should refrain from disclosing confidential, sensitive, private
        or privileged information through the Platform. Simplawfy is not liable
        for any breach of confidentiality or legal professional privilege in
        relation to any information or material submitted through the Platform.
      </p>
      <p>
        7.6
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Simplawfy cannot control, does not endorse, and is not responsible or
        liable in any manner for, any content posted by, or the actions or
        omissions of, Lawyers, Clients or other third party users of the Website
        or Platform. You are responsible for the accuracy and completeness of
        any content you provide through the Platform. Simplawfy may remove any
        content from the Platform at its absolute discretion.
      </p>
      <p>
        7.7
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        You agree to indemnify and hold harmless Simplawfy and its affiliates,
        officers, agents and employees from any claims, demands, liabilities,
        losses, costs or expenses (including without limitation solicitors and
        court costs), including from a third party, arising out of or in
        relation to your use of the Website or Platform, or your breach of the
        Terms or the law. This indemnity will survive the termination of the
        Terms.
      </p>
      <p>
        7.8
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Simplawfy will not be liable for, and you expressly release Simplawfy
        from, any damages of any kind arising from or in connection with the use
        of the Website or the Platform, including, but not limited to, direct,
        indirect, incidental, special, consequential or exemplary damages,
        including but not limited to any loss of profit (whether incurred
        directly or indirectly), goodwill, business reputation or other
        intangible loss.
      </p>

      <h6>
        8<span>&nbsp;&nbsp;&nbsp;</span>General
      </h6>

      <p>
        8.1
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Your use of the Website is also governed by the Website Terms of Use,
        which you should also read and must also comply with, and which is
        available
        <router-link to="makinglawsimple.com.au/terms-of-use">
          here</router-link>.
      </p>
      <p>
        8.2
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Simplawfy reserves the right to vary these Terms, at any time, by
        updating this page. Any variations to the Terms take immediate effect
        upon publication on this Website. Simplawfy encourages you to check this
        page often for any such variations. Your continued use of the Website
        and Platform following publication constitutes acceptance of any
        variations to the Terms. If you do not agree to any variations to the
        Terms, you must immediately delete your Account and cease using the
        Platform.
      </p>
      <p>
        8.3
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Except where the Platform’s functionality allows the Lawyer to
        communicate with Simplawfy by clicking links or buttons on the Website,
        all notices and communications given pursuant to these Terms must be in
        writing and given to the other party electronically at, in the case of
        the Lawyer, the email address provided by the Lawyer via the Platform
        and, in the case of Simplawfy, at
        <a href="mailto:info@makinglawsimple.com.au"
          >info@makinglawsimple.com.au</a
        >; and will be deemed to be delivered to the recipient upon being sent
        to that address.
      </p>
      <p>
        8.4
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Nothing in these Terms is intended to create a relationship of
        partnership, joint venture, agency or employment between either party,
        and neither party has authority to bind the other party.
      </p>
      <p>
        8.5
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        These Terms are governed by the laws of Western Australia and the
        parties submit to the non-exclusive jurisdiction of the courts of
        Western Australia.
      </p>
      <p>
        8.6
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        These Terms constitute the entire agreement between the parties on the subject 
        matter contained in them and supersede any prior agreements or understandings, 
        whether written or oral.
      </p>
      <p>
        8.7
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        All provisions of the Terms which by their nature should survive
        termination of these Terms survive termination including, without
        limitation, clause 7 Limitation of liability. Any accrued rights or
        liabilities of Simplawfy also survive termination of these Terms.
      </p>
      <p>
        8.8
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        In these Terms, headings are for convenience only and do not affect
        meaning.
      </p>
      <p>
        8.9
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        If any part of these Terms is found to be invalid or unenforceable by a
        court of competent jurisdiction, that part will be severed and the
        remainder of the Terms will continue in full force and effect.
      </p>
      <p>
        8.10
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        The failure of a party to exercise any right or provision of these Terms will not constitute a waiver of such right or provision.  
      </p>
      <p>
        8.11
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        No party may assign its rights and obligations under these Terms without the prior written consent of the other party. 
      </p>
    </div>
    <div class="footer">
      <MainFooter />
    </div>
  </section>
</template>
<script>
import GeneralHeader from "../pages/GeneralHeader.vue";
import MainFooter from "../components/global/MainFooter.vue";

import LawyerHeader from "../pages/lawyer/Header.vue";

export default {
  components: {
    GeneralHeader,
    MainFooter,

    LawyerHeader,
  },

  name: "terms-of-use-lawyer",
};
</script>
<style scoped>
h6 {
  font-size: 19px !important;
  font-weight: 400;
  color: black;
  line-height: 28px;
  margin-bottom: 1rem;
}
p {
  font-size: 1rem;
  color: black;
  margin-bottom: 1rem;
  line-height: 24px;
}
* {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  /* margin: 0; */
  padding: 0;
}
</style>
