<template lang="">
  <section class=" ">
    <div v-if="loginUserData && loginUserData.type == 'client'">
      <ClientHeader />
    </div>
    <div v-else>
      <GeneralHeader />
    </div>

    <div class="WordSection1 container pt-3">
      <h3 class="mt-3 text-center">Terms and Conditions for Clients</h3>

      <h6>1<span>&nbsp;&nbsp;&nbsp;</span>Introduction</h6>
      <p>
        1.1
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>These terms and
        conditions (<b>Terms</b>) govern your use of the website
        ‘makinglawsimple.com.au’ (<b>Website</b>) as a Client (defined in clause 1.2(a)
        of these Terms). The Website is operated by Simplawfy Pty Ltd
        (<b>Simplawfy</b>). By using the Website as a Client, you acknowledge that you
        have read, understood and agree to be bound by the Terms.
      </p>

      <p>
        1.2 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        The Website provides a platform (<b>Platform</b>) that facilitates the
        exchange of:
      </p>

      <p>
        (a) <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        requests for proposals for legal services (known as jobs) from potential
        clients, who register through the ‘I’m looking for a lawyer/Sign up to
        find a lawyer’ portal (<b>Clients</b>); and
      </p>
      <p>
        (b) <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        proposals for legal services from lawyers, who register through the ‘I’m
        looking for clients/Sign up to find clients’ portal (<b>Lawyers</b>).
      </p>
      <p>
        1.3 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        There is no cost to Clients to access the Platform, as Lawyers subscribe
        to a paid membership to access the Platform.
      </p>
      <p>
        1.4 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        In order to access the Platform as a Client and post jobs, you must hold
        an account on the Website (<b>Account</b>).
      </p>

      <h6>2<span>&nbsp;&nbsp;&nbsp;</span>Account</h6>
      <p>
        2.1 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        To create an Account, you will be required to provide some personal and
        contact details.
      </p>

      <p>
        2.2 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Whilst you hold an Account, you warrant that:
      </p>

      <p>
        (a) <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        all information you provide through the Platform is accurate, complete
        and current;
      </p>
      <p>
        (b) <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        if any information changes in relation to your Account, you will
        immediately update it accordingly;
      </p>
      <p>
        (c) <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        you have the right, authority and capacity to enter into legally binding
        contracts including agreeing to these Terms;
      </p>
      <p>
        (d) <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        if you are creating an Account on behalf of a business (including a
        company), you are duly authorised to bind the business, you act as an
        agent for and on behalf of that business for the purposes of these Terms
        and your use of the Website and Platform, and that business is taken to
        have accepted the Terms; and
      </p>
      <p>
        (e) <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        if any of the warranties referred to in this clause 2.2 become untrue,
        you will notify Simplawfy immediately.
      </p>
      <p>
        2.3 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        You are responsible for maintaining the security of your Account and for
        all activities that occur under your Account. You must notify Simplawfy
        immediately of any suspected or actual unauthorised use or any other
        breach of confidentiality or compromise of security in relation to your
        Account. Simplawfy will not be liable for any loss, damage or liability
        arising from the unauthorised use of your Account or computing device.
      </p>
      <p>
        2.4 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Simplawfy may suspend your Account (and thereby your access to the
        Platform) by written notice and with immediate effect if:
      </p>
      <p>
        (a) <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

        you breach any of these Terms including any of the warranties in clause
        2.2, or if Simplawfy has a reasonable basis to suspect that you may have
        done so, whilst an investigation is conducted; or
      </p>
      <p>
        (b) <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        you have notified Simplawfy of a breach or compromise of the security of
        your Account under clause 2.3.
      </p>
      <p>
        2.5 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        You may delete your Account by clicking ‘Delete Account’ on the Account
        page. If you wish to use the Platform again in future, you will need to
        create a new Account.
      </p>

      <h6>
        3<span>&nbsp;&nbsp;&nbsp;</span>Posting jobs, and receiving and
        accepting proposals
      </h6>

      <p>
        3.1 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        You may post jobs whilst you hold an Account.
      </p>

      <p>
        3.2 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        Jobs must contain details about the job title, job description, location
        and area of practice (and if you are uncertain of this, you should
        select ‘Other (not listed here) / I don’t know’), and may contain any
        accessibility requirements.
      </p>

      <p>
        3.3 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> Once your
        job has been posted, it may be visible to Lawyers on the Platform
        practicing in a practice area and jurisdiction matching your job. You
        may receive proposals from Lawyers through the Platform, which may
        include the following information:
      </p>

      <p>
        (a) <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> the Lawyer’s
        profile;
      </p>

      <p>
        (b) <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> the fee
        estimate (expressed as a particular charging method), any estimated
        disbursements and any upfront payment required; and
      </p>

      <p>
        (c) <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> the scope of
        proposed work.
      </p>

      <p>
        3.4 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> Clients may
        also receive or send messages to a Lawyer through the Platform, in which
        case the following applies:
      </p>

      <p>
        (a) <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> the
        messaging facility is provided for the sole purpose of allowing either a
        Lawyer or Client to seek further information or clarification, in order
        for a Lawyer to submit a proposal or a Client to consider a Lawyer’s
        submitted proposal;
      </p>

      <p>
        (b)<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> Lawyers may
        only send 1 message to Clients initially, but will be able to
        communicate freely if the Client responds;
      </p>

      <p>
        (c) <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> you must not
        use the messaging facility to spam Lawyers with an unnecessary or
        unreasonable volume of messages;
      </p>

      <p>
        (d) <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> you must not
        use the messaging facility to bypass the proper proposal acceptance
        process; and
      </p>

      <p>
        (e) <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> you are
        responsible for the content and accuracy of any information you
        communicate to the Lawyer through any messages.
      </p>

      <p>
        3.5 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> You may
        choose to accept or reject a proposal. You are solely responsible for
        your choice in accepting or rejecting any Lawyer’s proposal, and your
        choice is final (in respect of that job on the Platform).
      </p>

      <p>
        3.6 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> Lawyers may
        withdraw a submitted proposal prior to the Client’s acceptance, for
        instance if they are no longer in a position to honour the terms of it.
        Lawyers may not submit a new proposal for the same job, except as
        permitted by Simplawfy.
      </p>

      <p>
        3.7 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> You may
        cancel a job prior to accepting a Lawyer’s proposal, for instance if it
        is no longer required.
      </p>

      <p>
        3.8 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> If you
        accept a Lawyer’s proposal, the proposal process and any messaging will
        be closed. Your contact details and the contact details of the Lawyer
        whose proposal you accepted (<b>Accepted Lawyer</b>) will be exchanged,
        to facilitate your continuation of the legal matter directly with the
        Accepted Lawyer. All further communications between you and the Accepted
        Lawyer must be made off the Platform and you will be unable to continue
        to correspond through the Platform in relation to that job.
      </p>

      <p>
        3.9 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> In respect
        of an Accepted Lawyer, you agree and acknowledge that a proposal does
        not constitute an offer or acceptance of a contract, and neither a
        contract nor a lawyer/client relationship is formed between you and any
        Lawyer (including an Accepted Lawyer), unless and until you have
        formally engaged that Accepted Lawyer. You are responsible for all
        aspects of the subsequent legal engagement with the Accepted Lawyer, as
        if you had sought the Accepted Lawyer’s services directly, including but
        not limited to liaising with the Accepted Lawyer; signing any engagement
        letter or costs agreement; paying any upfront payments into trust;
        providing further instructions, information or evidence; negotiating any
        additional work; paying any legal fees, costs and disbursements to the
        Accepted Lawyer; and making any complaints regarding the Accepted
        Lawyer’s work, fees, conduct or any other matter.
      </p>

      <p>
        3.10 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> Following
        the engagement with the Accepted Lawyer, you may be invited to leave a
        review and rating in respect of the services rendered by the Accepted
        Lawyer. Simplawfy is not responsible for any reviews and ratings left by
        you. The Accepted Lawyer may have an avenue of appeal of a review or
        rating on the basis that they consider it unfair or inaccurate.
        Simplawfy may, at its absolute discretion, change, alter or remove the
        rating and/or review if it considers it reasonable to do so, but is not
        liable for doing or failing to do so.
      </p>

      <p>
        3.11 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> Simplawfy
        makes no warranty, representation or guarantee in respect of:
      </p>

      <p>
        (a) <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> the number,
        type, suitability or legitimacy of Lawyers that will use the Platform;
      </p>

      <p>
        (b) <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> the number,
        type, suitability or legitimacy of any proposals that will be made
        through the Platform;
      </p>

      <p>
        (c) <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> the
        truthfulness, completeness or accuracy of any information provided by a
        Lawyer through the Platform;
      </p>

      <p>
        (d) <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> whether
        Lawyers will submit a proposal to or respond to a Client’s job or
        message; or
      </p>

      <p>
        (e) <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> any aspect
        of the relationship between the Client and any Accepted Lawyer
        including, without limitation, that the Accepted Lawyer will proceed
        with the engagement, that the work required to be performed or the fees
        charged by the Accepted Lawyer will reflect the accepted proposal, or
        concerning the quality, timeliness, adequateness, or fitness for purpose
        of any legal advice, opinions, services or representation provided by
        the Accepted Lawyer or any personnel of its law practice.
      </p>

      <h6>4<span>&nbsp;&nbsp;&nbsp;</span>Dispute resolution</h6>

      <p>
        4.1 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> In the event
        a dispute arises between the Client and Simplawfy in relation to the
        Platform or these Terms, the parties agree to deal with the dispute in
        accordance with the provisions of this clause 4 Dispute resolution.
      </p>
      <p>
        4.2 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> A party
        claiming a dispute has arisen must, prior to instituting legal action,
        give written notice to the other party particularising the nature of the
        dispute.
      </p>
      <p>
        4.3 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> Upon receipt
        of the notice referred to in clause 4.2, the parties must meet within 7
        days and endeavour in good faith to resolve the dispute.
      </p>
      <p>
        4.4 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> If, after
        the meeting referred to in clause 4.3 is held, the dispute remains
        unresolved, the parties must agree upon a mediator to be appointed (and
        where the parties cannot agree, a mediator is to be appointed by the
        President of the Law Society or Law Institute in the state or territory
        where the Client is situated), and attend a mediation conducted by that
        mediator (which may be conducted virtually), with the parties to bear
        equally the costs of the mediator and mediation, and to bear their own
        costs.
      </p>
      <p>
        4.5 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> If, after
        the mediation referred to in clause 4.4 is held, the dispute remains
        unresolved, either party may institute legal proceedings concerning the
        dispute.
      </p>
      <p>
        4.6 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> All
        communications concerning negotiations made by the parties in the course
        of attempting to resolve the dispute using this clause 4 Dispute
        resolution are to be treated as confidential and without prejudice.
      </p>

      <h6>5<span>&nbsp;&nbsp;&nbsp;</span> Limitation of liability</h6>

      <p>
        5.1 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> The Platform
        provided by Simplawfy is a platform to facilitate the connecting of
        Clients and Lawyers only. Simplawfy is not a lawyer, law firm, legal
        practice, list or panel of lawyers, employment agency, or legal
        professional referral service, and does not engage in legal practice
        itself. Simplawfy does not select, recommend, approve or endorse any
        particular Lawyer or Client. Nothing in the Website or Platform is to be
        construed as an offer or provision of legal advice, services, opinions
        or representation given by Simplawfy or its affiliates, officers, agents
        or employees, or the creation of any lawyer/client relationship.
      </p>

      <p>
        5.2 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> Simplawfy is
        not a contractual party to, is not involved in, and will not have any
        liability with respect to, any work, legal matter, engagement or
        communications between Clients and Lawyers, whether or not made through
        the Platform. Lawyers are independent of Simplawfy and are not employees
        or consultants of Simplawfy. Any claims between Clients and Lawyers
        arising out of the legal advice, services, opinions or representation
        provided by Lawyers or their law practice, legal fees, disbursements or
        costs payable by Clients, or any other matter, is to be brought against
        the Lawyer or Client individually and not Simplawfy. You indemnify
        Simplawfy from all claims, suits, demands and legal actions arising from
        any and all disputes between a Lawyer and you as a Client.
      </p>
      <p>
        5.3 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> While
        Simplawfy uses commercially reasonable efforts to verify that the
        Lawyers on the Platform are registered Australian legal practitioners at
        their time of approval to use the Platform, it does not make any
        warranty, representation or guarantee as to the legitimacy,
        qualifications, certifications, legal ability, competence, quality,
        fitness for purpose, insurance coverage or potentially achievable
        results of any Lawyer. Simplawfy makes no warranty, representation or
        guarantee in relation to any Client. Simplawfy encourages Clients and
        Lawyers to make all necessary enquiries to satisfy themselves as to the
        suitability of the counterparty.
      </p>
      <p>
        5.4 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> To the
        fullest extent permitted by law, Simplawfy excludes all representations,
        warranties or guarantees, whether express or implied, by statute, trade
        or otherwise, as to the operation of the Website or the contents of it,
        including the Platform.
      </p>
      <p>
        5.5 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> Any job
        details, proposals and messages made via the Platform may not be
        confidential or protected by legal professional privilege. Lawyers and
        Clients should refrain from disclosing confidential, sensitive, private
        or privileged information through the Platform. Simplawfy is not liable
        for any breach of confidentiality or legal professional privilege in
        relation to any information or material submitted through the Platform.
      </p>
      <p>
        5.6 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> Simplawfy
        cannot control, does not endorse, and is not responsible or liable in
        any manner for, any content posted by, or the actions or omissions of,
        Lawyers, Clients or other third party users of the Website or Platform.
        You are responsible for the accuracy and completeness of any content you
        provide through the Platform. Simplawfy may remove any content from the
        Platform at its absolute discretion.
      </p>
      <p>
        5.7 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> You agree to
        indemnify and hold harmless Simplawfy and its affiliates, officers,
        agents and employees from any claims, demands, liabilities, losses,
        costs or expenses (including without limitation solicitors and court
        costs), including from a third party, arising out of or in relation to
        your use of the Website or Platform, or your breach of the Terms or the
        law. This indemnity will survive the termination of the Terms.
      </p>
      <p>
        5.8 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> Simplawfy
        will not be liable for, and you expressly release Simplawfy from, any
        damages of any kind arising from or in connection with the use of the
        Website or the Platform, including, but not limited to, direct,
        indirect, incidental, special, consequential or exemplary damages,
        including but not limited to any loss of profit (whether incurred
        directly or indirectly), goodwill, business reputation or other
        intangible loss.
      </p>
      <h6>6<span>&nbsp;&nbsp;&nbsp;</span>General</h6>

      <p>
        6.1 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> Your use of
        the Website is also governed by the Website Terms of Use, which you
        should also read and must also comply with, and which is available
        <router-link to="makinglawsimple.com.au/terms-of-use">
          here</router-link>.
      </p>
      <p>
        6.2 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> Simplawfy
        reserves the right to vary these Terms, at any time, by updating this
        page. Any variations to the Terms take immediate effect upon publication
        on this Website. Simplawfy encourages you to check this page often for
        any such variations. Your continued use of the Website and Platform
        following publication constitutes acceptance of any variations to the
        Terms. If you do not agree to any variations to the Terms, you must
        immediately delete your Account and cease using the Platform.
      </p>
      <p>
        6.3 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> Except where
        the Platform’s functionality allows the Client to communicate with
        Simplawfy by clicking links or buttons on the Website, all notices and
        communications given pursuant to these Terms must be in writing and
        given to the other party electronically at, in the case of the Client,
        the email address provided by the Client via the Platform and, in the
        case of Simplawfy, at
        <a href="mailto:info@makinglawsimple.com.au"
          >info@makinglawsimple.com.au</a
        >; and will be deemed to be delivered to the recipient upon being sent
        to that address.
      </p>
      <p>
        6.4 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> Nothing in
        these Terms is intended to create a relationship of partnership, joint
        venture, agency or employment between either party, and neither party
        has authority to bind the other party.
      </p>
      <p>
        6.5 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>These Terms
        are governed by the laws of Western Australia and the parties submit to
        the non-exclusive jurisdiction of the courts of Western Australia.
      </p>
      <p>
        6.6 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> These Terms
        constitute the entire agreement between the parties on the subject
        matter contained in them and supersede any prior agreements or
        understandings, whether written or oral.
      </p>
      <p>
        6.7 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> All
        provisions of the Terms which by their nature should survive termination
        of these Terms survive termination including, without limitation, clause
        5 Limitation of liability. Any accrued rights or liabilities of
        Simplawfy also survive termination of these Terms.
      </p>
      <p>
        6.8 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> In these
        Terms, headings are for convenience only and do not affect meaning.
      </p>
      <p>
        6.9 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> If any part
        of these Terms is found to be invalid or unenforceable by a court of
        competent jurisdiction, that part will be severed and the remainder of
        the Terms will continue in full force and effect.
      </p>
      <p>
        6.10 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> The failure
        of a party to exercise any right or provision of these Terms will not
        constitute a waiver of such right or provision.
      </p>
      <p>
        6.11 <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> No party
        may assign its rights and obligations under these Terms without the
        prior written consent of the other party.
      </p>
    </div>
    <div class="footer">
      <MainFooter />
    </div>
  </section>
</template>
<script>
import GeneralHeader from "../pages/GeneralHeader.vue";
import MainFooter from "../components/global/MainFooter.vue";
import ClientHeader from "../pages/client/Header.vue";

export default {
  components: {
    GeneralHeader,
    MainFooter,

    ClientHeader,
  },

  name: "terms-of-use-client",
};
</script>
<style scoped>
h6 {
  font-size: 19px !important;
  font-weight: 400;
  color: black;
  line-height: 28px;
  margin-bottom: 1rem;
}
p {
  font-size: 1rem;
  color: black;
  margin-bottom: 1rem;
  line-height: 24px;
}
* {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  /* margin: 0; */
  padding: 0;
}
</style>
