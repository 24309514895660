<template lang="">
  <section class="faq-main">
    <!-- <GeneralHeader /> -->
    <LawyerHeader />
    <div class="WordSection1 container" id="lawyer-faq">
      <!-- ----------- -->
      <h3 class="mt-3 mb-3" style="text-align: center">FAQs</h3>

      <!--l faqs ---------- -->

      <p class="mt-3 mb-3" style="font-size: 15px">
        For the purposes of these FAQs, when we refer to ‘client’, we are
        referring to those using our platform to request legal services.
      </p>
      <!-- ------- -->
      <div class="accordion accordion-flush">
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingOnel">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOnel"
              aria-expanded="false"
              aria-controls="flush-collapseOnel"
            >
              <p
                class="LNNumberedHeading1 mt-2 mb-2"
                style="
                  margin-left: 0cm;
                  text-indent: 0cm;
                  mso-pagination: none;
                  page-break-after: auto;
                  mso-list: none;
                  tab-stops: 36pt;
                "
              >
                <span
                  lang="EN-US"
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 400;
                  "
                >
                  <b>I have created a profile, but</b>
                </span>
              </p>
            </button>
          </h2>
          <div
            id="flush-collapseOnel"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingOnel"
          >
            <div class="accordion-body">
              <div class="accordion" id="accordionExample1">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headinglOne">
                    <button
                      class="accordion-button collapsed"
                      style="font-weight: 400"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapselOne"
                      aria-expanded="true"
                      aria-controls="collapselOne"
                    >
                      Why do I have to be verified before I can subscribe and
                      view jobs?
                    </button>
                  </h2>
                  <div
                    id="collapselOne"
                    class="accordion-collapse collapse"
                    aria-labelledby="headinglOne"
                    data-bs-parent="#accordionExample1"
                  >
                    <div class="accordion-body">
                      Anyone who applies to be a lawyer on our platform
                      undergoes a verification process to ensure that they are
                      registered to practice law in their state/territory and
                      that they meet our eligibility requirements, prior to
                      being able to subscribe and submit proposals. This is to
                      ensure that we maintain client confidence in the integrity
                      of the platform.
                    </div>
                  </div>
                </div>
              </div>
              <!-- ------ -->
              <div class="accordion" id="accordionExample12">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="heading1Two">
                    <button
                      class="accordion-button collapsed"
                      style="font-weight: 400"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsel1Two"
                      aria-expanded="true"
                      aria-controls="collapsel1Two"
                    >
                      Why can’t I amend my name or state/territory without your
                      approval?
                    </button>
                  </h2>
                  <div
                    id="collapsel1Two"
                    class="accordion-collapse collapse"
                    aria-labelledby="heading1Two"
                    data-bs-parent="#accordionExample12"
                  >
                    <div class="accordion-body">
                      You can amend most fields of your profile without
                      restriction. However, some fields require our approval to
                      amend because they relate to the verification process (for
                      an explanation of the verification process, see
                      <button
                        class="accordion-button collapsed cstm-accor"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapselOne"
                        aria-expanded="false"
                        aria-controls="collapselOne"
                        id="scToItem"
                        v-on:click="hideButtonAndScroll()"
                      >
                        Why do I have to be verified before I can subscribe and
                        view jobs?)</button
                      >.
                    </div>
                  </div>
                </div>
              </div>
              <!---->
            </div>
          </div>
        </div>
        <!-- ------ -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingOnell">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOnell"
              aria-expanded="false"
              aria-controls="flush-collapseOnell"
            >
              <p
                class="LNNumberedHeading1 mt-2 mb-2"
                style="
                  margin-left: 0cm;
                  text-indent: 0cm;
                  mso-pagination: none;
                  page-break-after: auto;
                  mso-list: none;
                  tab-stops: 36pt;
                "
              >
                <span
                  lang="EN-US"
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 400;
                  "
                >
                  <b>I am considering subscribing to your platform, but</b>
                </span>
              </p>
            </button>
          </h2>
          <div
            id="flush-collapseOnell"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingOnell"
          >
            <div class="accordion-body">
              <div class="accordion">
                <div class="accordion" id="accordionExample1">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headinglTwo">
                      <button
                        class="accordion-button collapsed"
                        style="font-weight: 400"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapselTwo"
                        aria-expanded="false"
                        aria-controls="collapselTwo"
                      >
                        Why do you charge a monthly fee?
                      </button>
                    </h2>
                    <div
                      id="collapselTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headinglTwo"
                      data-bs-parent="#accordionExample1"
                    >
                      <div class="accordion-body">
                        We charge a $99 subscription fee per month (following a
                        60-day free trial) to access the platform. We feel this
                        is more transparent, fair and predictable than charging
                        a commission, finder’s fee or success fee — the fee is
                        the same regardless of the amount of business you get
                        through our platform.
                      </div>
                    </div>
                  </div>
                  <!-- ----- -->
                </div>
              </div>
              <!-- ------ -->
            </div>
          </div>
        </div>
        <!-- ------ -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingOnelll">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOnelll"
              aria-expanded="false"
              aria-controls="flush-collapseOnelll"
            >
              <p
                class="LNNumberedHeading1 mt-2 mb-2"
                style="
                  margin-left: 0cm;
                  text-indent: 0cm;
                  mso-pagination: none;
                  page-break-after: auto;
                  mso-list: none;
                  tab-stops: 36pt;
                "
              >
                <span
                  lang="EN-US"
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 400;
                  "
                >
                  <b> I am considering submitting a proposal for a job, but</b>
                </span>
              </p>
            </button>
          </h2>
          <div
            id="flush-collapseOnelll"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingOnelll"
          >
            <div class="accordion-body">
              <div class="accordion">
                <div class="accordion" id="accordionExample1">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headinglThree">
                      <button
                        class="accordion-button collapsed"
                        style="font-weight: 400"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapselThree"
                        aria-expanded="false"
                        aria-controls="collapselThree"
                      >
                        I am not sure what to say under ‘Say what you will do
                        for the client’ or how to estimate my costs.
                      </button>
                    </h2>
                    <div
                      id="collapselThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headinglThree"
                      data-bs-parent="#accordionExample1"
                    >
                      <div class="accordion-body">
                        We understand it can be difficult in some legal matters
                        to articulate the full scope of the services to be
                        provided to the client. Don’t forget you are able to
                        send one message to the client where you can ask the
                        client any questions to seek any further information or
                        clarification necessary for you to submit a proposal.
                        <br /><br />
                        We suggest you try to set out, in as much detail as
                        possible, the services you will provide to the client,
                        as closely as you would do if the client had contacted
                        you directly through your website or as you would be
                        required to do ordinarily on a costs disclosure form.
                        Remember, the information you provide through our
                        platform is not intended to replace your disclosure
                        requirements to clients under applicable legal
                        profession laws — you should still issue a separate
                        costs disclosure statement to your client where
                        required.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headinglfour">
                      <button
                        class="accordion-button collapsed"
                        style="font-weight: 400"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapselfour"
                        aria-expanded="false"
                        aria-controls="collapselfour"
                      >
                        Why can’t I message the client more than once initially?
                      </button>
                    </h2>
                    <div
                      id="collapselfour"
                      class="accordion-collapse collapse"
                      aria-labelledby="headinglfour"
                      data-bs-parent="#accordionExample1"
                    >
                      <div class="accordion-body">
                        To avoid the potential for harassment of clients, we
                        have restricted lawyers to sending only one message to
                        clients initially. The intention is to allow the lawyer
                        an opportunity to ask the client any questions to seek
                        any further information or clarification necessary for
                        the lawyer to submit a proposal. If the client responds,
                        this will open the messaging channel and you will be
                        free to respond and communicate further with the client.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ------ -->
            </div>
          </div>
        </div>
        <!-- ------ -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingOnellll">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOnellll"
              aria-expanded="false"
              aria-controls="flush-collapseOnellll"
            >
              <p
                class="LNNumberedHeading1 mt-2 mb-2"
                style="
                  margin-left: 0cm;
                  text-indent: 0cm;
                  mso-pagination: none;
                  page-break-after: auto;
                  mso-list: none;
                  tab-stops: 36pt;
                "
              >
                <span
                  lang="EN-US"
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 400;
                  "
                >
                  <b>I have submitted a proposal to a client, but</b>
                </span>
              </p>
            </button>
          </h2>
          <div
            id="flush-collapseOnellll"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingOnellll"
          >
            <div class="accordion-body">
              <div class="accordion">
                <div class="accordion" id="accordionExample1">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headinglfive">
                      <button
                        class="accordion-button collapsed"
                        style="font-weight: 400"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapselfive"
                        aria-expanded="false"
                        aria-controls="collapselfive"
                      >
                        What if they ask for a discount on my costs estimate?
                      </button>
                    </h2>
                    <div
                      id="collapselfive"
                      class="accordion-collapse collapse"
                      aria-labelledby="headinglfive"
                      data-bs-parent="#accordionExample1"
                    >
                      <div class="accordion-body">
                        Our position on discounting is that it is up to the
                        individual lawyer to decide whether they will entertain
                        such a request. We understand that some lawyers will be
                        opposed to reducing the perceived value of their work,
                        whilst for other lawyers discounting is routine
                        practice. <br /><br />
                        If a client requests a discount, you should be clear
                        whether this is accepted or not in your messages through
                        the messaging system. This is because, as you are unable
                        to amend your proposal (for an explanation, see
                        <button
                          class="accordion-button collapsed cstm-accor"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapselsix"
                          aria-expanded="false"
                          aria-controls="collapselsix"
                          id="scToItem"
                          v-on:click="hideButtonAndScroll()"
                        >
                          Why can’t I amend my proposal?</button
                        >), the agreed discounted estimate needs to be reflected
                        in the costs disclosure and costs agreement you provide
                        to the client once they have accepted your proposal. If
                        the client insists on your proposal reflecting the
                        agreed discount, please
                        <router-link to="/contact-us">contact us</router-link>
                        and we will manually reflect the proposal.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headinglsix">
                      <button
                        class="accordion-button collapsed"
                        style="font-weight: 400"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapselsix"
                        aria-expanded="false"
                        aria-controls="collapselsix"
                      >
                        Why can’t I amend my proposal?
                      </button>
                    </h2>
                    <div
                      id="collapselsix"
                      class="accordion-collapse collapse"
                      aria-labelledby="headinglsix"
                      data-bs-parent="#accordionExample1"
                    >
                      <div class="accordion-body">
                        Once you have submitted a proposal, you are not able to
                        amend it. You can only withdraw the proposal, in which
                        case you are prevented from submitting a new proposal
                        for the same job (as this would have the same effect as
                        amending it). We prevent amendments to proposals for 2
                        main reasons:
                        <br /><br />
                        <ul>
                          <li>
                            we want to ensure clients have the certainty that
                            the terms of a proposal they have seen do not
                            change; and
                          </li>
                          <li>
                            we don’t want to encourage bidding wars between
                            lawyers by allowing clients to ask lawyers to match
                            better offers they have received.
                          </li>
                        </ul>

                        Whilst this may be inconvenient, we hope that you will
                        understand that we made this decision in the interests
                        of maintaining the integrity of the platform and the
                        legal industry.
                        <br /><br />
                        In the event that you have made a mistake on a submitted
                        proposal, and you wish to have the opportunity to
                        correct it rather than withdrawing the proposal, please
                        <router-link to="/contact-us">contact us</router-link>
                        and explain the details of the mistake. We will consider
                        such requests on an exceptional basis and, if we agree,
                        will manually amend your proposal accordingly.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headinglseven">
                      <button
                        class="accordion-button collapsed"
                        style="font-weight: 400"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapselseven"
                        aria-expanded="false"
                        aria-controls="collapselseven"
                      >
                        Why can’t I message the client?
                      </button>
                    </h2>
                    <div
                      id="collapselseven"
                      class="accordion-collapse collapse"
                      aria-labelledby="headinglseven"
                      data-bs-parent="#accordionExample1"
                    >
                      <div class="accordion-body">
                        To avoid the potential for harassment of clients, we
                        have restricted messaging of clients once you have
                        submitted a proposal. Of course, if a client messages
                        you regarding your proposal, this will open the
                        messaging channel and you will be free to respond and
                        communicate further with the client.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingleight">
                      <button
                        class="accordion-button collapsed"
                        style="font-weight: 400"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseleight"
                        aria-expanded="false"
                        aria-controls="collapseleight"
                      >
                        It’s been a while and my proposal hasn’t been accepted
                        or rejected.
                      </button>
                    </h2>
                    <div
                      id="collapseleight"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingleight"
                      data-bs-parent="#accordionExample1"
                    >
                      <div class="accordion-body">
                        There can be several reasons why clients do not accept
                        any lawyer’s proposal on the platform, including that
                        they have abandoned the platform without letting us
                        know. We have processes in place to follow up clients,
                        but cannot guarantee that they will follow through with
                        accepting any proposal. Therefore, we suggest that you
                        consider withdrawing any proposals that you consider
                        stale and that you would no longer honour because too
                        much time has passed.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ------ -->
            </div>
          </div>
        </div>
        <!-- ------ -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingOnellla">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOnellla"
              aria-expanded="false"
              aria-controls="flush-collapseOnellla"
            >
              <p
                class="LNNumberedHeading1 mt-2 mb-2"
                style="
                  margin-left: 0cm;
                  text-indent: 0cm;
                  mso-pagination: none;
                  page-break-after: auto;
                  mso-list: none;
                  tab-stops: 36pt;
                "
              >
                <span
                  lang="EN-US"
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 400;
                  "
                >
                  <b>A client has accepted my proposal, but</b>
                </span>
              </p>
            </button>
          </h2>
          <div
            id="flush-collapseOnellla"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingOnellla"
          >
            <div class="accordion-body">
              <div class="accordion">
                <div class="accordion" id="accordionExample1">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headinglnine">
                      <button
                        class="accordion-button collapsed"
                        style="font-weight: 400"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapselnine"
                        aria-expanded="false"
                        aria-controls="collapselnine"
                      >
                        Why can’t I continue to message them through the
                        platform?
                      </button>
                    </h2>
                    <div
                      id="collapselnine"
                      class="accordion-collapse collapse"
                      aria-labelledby="headinglnine"
                      data-bs-parent="#accordionExample1"
                    >
                      <div class="accordion-body">
                        It is our intention that, once a client has accepted
                        your proposal, the formal engagement and conduct of the
                        matter take place off the platform. For this reason,
                        once a proposal has been accepted, we exchange your
                        contact details with your client to allow you to
                        communicate further externally. We also provide a
                        transcript of any messages exchanged between you and the
                        client, so that you can pick up where you left off.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headinglten">
                      <button
                        class="accordion-button collapsed"
                        style="font-weight: 400"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapselten"
                        aria-expanded="false"
                        aria-controls="collapselten"
                      >
                        I cannot contact them using the contact details
                        provided.
                      </button>
                    </h2>
                    <div
                      id="collapselten"
                      class="accordion-collapse collapse"
                      aria-labelledby="headinglten"
                      data-bs-parent="#accordionExample1"
                    >
                      <div class="accordion-body">
                        If you are unable to contact a client using either their
                        email address or phone number provided to you when they
                        accept your proposal, please
                        <router-link to="/contact-us">contact us</router-link>
                        so that we can attempt to follow up with the client.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingl32ten">
                      <button
                        class="accordion-button collapsed"
                        style="font-weight: 400"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsel32ten"
                        aria-expanded="false"
                        aria-controls="collapsel32ten"
                      >
                        Do I still need to send them a costs disclosure
                        statement or costs agreement?
                      </button>
                    </h2>
                    <div
                      id="collapsel32ten"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingl32ten"
                      data-bs-parent="#accordionExample1"
                    >
                      <div class="accordion-body">
                        Yes, the information you have provided to the client in
                        your proposal does not replace any requirements under
                        applicable legal profession laws to provide the client
                        with a costs disclosure statement where the estimated
                        cost exceeds the relevant threshold, or a costs
                        agreement where required by you or the client. The
                        details of the costs disclosure statement or costs
                        agreement should generally match the terms of the
                        proposal the client has accepted.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingl312ten">
                      <button
                        class="accordion-button collapsed"
                        style="font-weight: 400"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsel312ten"
                        aria-expanded="false"
                        aria-controls="collapsel312ten"
                      >
                        They are refusing to sign my costs agreement.
                      </button>
                    </h2>
                    <div
                      id="collapsel312ten"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingl312ten"
                      data-bs-parent="#accordionExample1"
                    >
                      <div class="accordion-body">
                        Please
                        <router-link to="/contact-us">contact us</router-link>
                        and tell us of the client’s refusal, so that we can
                        contact them and ask for an explanation. Whilst we
                        cannot compel a client to follow through with an
                        engagement that they accepted a proposal for, it goes
                        against the spirit of the platform, so we want to help
                        resolve such disagreements amicably.
                      </div>
                    </div>
                  </div>

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingltwel">
                      <button
                        class="accordion-button collapsed"
                        style="font-weight: 400"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseltwel"
                        aria-expanded="false"
                        aria-controls="collapseltwel"
                      >
                        I ran a conflict check, and realised I am conflicted out
                        of acting for the client.
                      </button>
                    </h2>
                    <div
                      id="collapseltwel"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingltwel"
                      data-bs-parent="#accordionExample1"
                    >
                      <div class="accordion-body">
                        There might be instances when, upon being provided with
                        the details of an accepting client, you run a conflict
                        check and identify a conflict of interest.
                        Unfortunately, you won’t be able to act for the client
                        and they will need to engage an alternative lawyer.
                        Please
                        <router-link to="/contact-us">contact us</router-link>
                        and tell us that you are unable to act for the client,
                        so that we can reinstate the client’s job on the
                        Simplawfy platform.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headinglthirteen">
                      <button
                        class="accordion-button collapsed"
                        style="font-weight: 400"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapselthirteen"
                        aria-expanded="false"
                        aria-controls="collapselthirteen"
                      >
                        I am no longer able to accept the engagement for any
                        reason, e.g. I am too busy and don’t have capacity, I am
                        going on leave, etc
                      </button>
                    </h2>
                    <div
                      id="collapselthirteen"
                      class="accordion-collapse collapse"
                      aria-labelledby="headinglthirteen"
                      data-bs-parent="#accordionExample1"
                    >
                      <div class="accordion-body">
                        We understand that your circumstances may change, making
                        you unable to take the engagement. If you are no longer
                        able to act for the client, they will need to engage an
                        alternative lawyer. Please
                        <router-link to="/contact-us">contact us</router-link>
                        and tell us that you are unable to act for the client,
                        so that we can reinstate the client’s job on the
                        Simplawfy platform. If you are not in a position to take
                        any new engagements for a particular period, consider
                        whether you need to withdraw any open proposals you have
                        made on other jobs.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ------ -->
            </div>
          </div>
        </div>
        <!-- ------ -->
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingOnelllaa">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOnelllaa"
              aria-expanded="false"
              aria-controls="flush-collapseOnelllaa"
            >
              <p
                class="LNNumberedHeading1 mt- mb-2"
                style="
                  margin-left: 0cm;
                  text-indent: 0cm;
                  mso-pagination: none;
                  page-break-after: auto;
                  mso-list: none;
                  tab-stops: 36pt;
                "
              >
                <span
                  lang="EN-US"
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 400;
                  "
                >
                  <b>I want to message a client through the platform, but</b>
                </span>
              </p>
            </button>
          </h2>
          <div
            id="flush-collapseOnelllaa"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingOnelllaa"
          >
            <div class="accordion-body">
              <div class="accordion">
                <div class="accordion" id="accordionExample1">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headinglfourty">
                      <button
                        class="accordion-button collapsed"
                        style="font-weight: 400"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapselfourty"
                        aria-expanded="false"
                        aria-controls="collapselfourty"
                      >
                        Why can't I message them once initially?
                      </button>
                    </h2>
                    <div
                      id="collapselfourty"
                      class="accordion-collapse collapse"
                      aria-labelledby="headinglfourty"
                      data-bs-parent="#accordionExample1"
                    >
                      <div class="accordion-body">
                        To avoid the potential for harassment of clients, we
                        have restricted messaging of clients once you have
                        submitted a proposal. Of course, if a client messages
                        you regarding your proposal, this will open the
                        messaging channel and you will be free to respond and
                        communicate further with the client
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headinglfifty">
                      <button
                        class="accordion-button collapsed"
                        style="font-weight: 400"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapselfifty"
                        aria-expanded="false"
                        aria-controls="collapselfifty"
                      >
                        Why can’t I continue to message them through the
                        platform once they have accepted my proposal?
                      </button>
                    </h2>
                    <div
                      id="collapselfifty"
                      class="accordion-collapse collapse"
                      aria-labelledby="headinglfifty"
                      data-bs-parent="#accordionExample1"
                    >
                      <div class="accordion-body">
                        It is our intention that, once a client has accepted
                        your proposal, you proceed with the formal client
                        engagement and continue the matter directly with your
                        client. <br />
                        <br />

                        We will provide you with your client’s contact details,
                        details of the job and accepted proposal, and a
                        transcript of any messages exchanged, so that you have
                        everything you need to continue corresponding with your
                        client directly.
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingl2fifty">
                      <button
                        class="accordion-button collapsed"
                        style="font-weight: 400"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsel2fifty"
                        aria-expanded="false"
                        aria-controls="collapsel2fifty"
                      >
                        Why can’t I ask for or provide personal contact details?
                      </button>
                    </h2>
                    <div
                      id="collapsel2fifty"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingl2fifty"
                      data-bs-parent="#accordionExample1"
                    >
                      <div class="accordion-body">
                        To avoid the potential of harassment of clients, and to
                        make the proposal acceptance process clear for lawyers,
                        we intend that clients and lawyers communicate
                        exclusively through the platform, until a client has
                        accepted a lawyer’s proposal. Once that happens, we will
                        exchange your contact details with your client, so that
                        you can communicate directly.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ------ -->
            </div>
          </div>
        </div>
        <!-- ------- -->
        <!-- <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingOnelllaaa">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOnelllaaa"
              aria-expanded="false"
              aria-controls="flush-collapseOnelllaaa"
            >
              <p
                class="LNNumberedHeading1 mt- mb-2"
                style="
                  margin-left: 0cm;
                  text-indent: 0cm;
                  mso-pagination: none;
                  page-break-after: auto;
                  mso-list: none;
                  tab-stops: 36pt;
                "
              >
                <span
                  lang="EN-US"
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 400;
                  "
                >
                  <b>I am a lawyer outside of Australia.</b>
                </span>
              </p>
            </button>
          </h2>
          <div
            id="flush-collapseOnelllaaa"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingOnelllaaa"
          >
            <div class="accordion-body">
              <div class="accordion">
                <div class="accordion-body">
                  Unfortunately, our platform currently only caters to
                  Australian lawyers. Please
                  <router-link to="/contact-us">contact us</router-link> and let
                  us know where you’re located, and we’ll let you know if we
                  have plans to expand our platform to include lawyers in your
                  country.
                </div>
              </div>
             
            </div>
          </div>
        </div> -->

        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingOnelllaaa">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOnelllaaa"
              aria-expanded="false"
              aria-controls="flush-collapseOnelllaaa"
            >
              <p
                class="LNNumberedHeading1 mt- mb-2"
                style="
                  margin-left: 0cm;
                  text-indent: 0cm;
                  mso-pagination: none;
                  page-break-after: auto;
                  mso-list: none;
                  tab-stops: 36pt;
                "
              >
                <span
                  lang="EN-US"
                  style="
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 400;
                  "
                >
                  <b
                    >I have completed a legal matter with a client through the
                    platform, but
                  </b>
                </span>
              </p>
            </button>
          </h2>
          <div
            id="flush-collapseOnelllaaa"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingOnelllaaa"
          >
            <div class="accordion-body">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingl22fifty">
                  <button
                    class="accordion-button collapsed"
                    style="font-weight: 400"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsel22fifty"
                    aria-expanded="false"
                    aria-controls="collapsel22fifty"
                  >
                    They have left me a low rating or negative review (which I
                    think is unjustified)
                  </button>
                </h2>
                <div
                  id="collapsel22fifty"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingl22fifty"
                  data-bs-parent="#accordionExample1"
                >
                  <div class="accordion-body">
                    We encourage clients to rate and review their lawyers after
                    the completion of their legal matter, as this helps inform
                    future clients about their experience. At the same time, we
                    understand that clients’ feedback can be unreasonable or
                    unjustified. For this reason, we allow lawyers the right to
                    respond to a review of them left by their client, to give
                    their perspective. <br />
                    <br />

                    If you are concerned about a low rating or negative review
                    left by a client, that you don’t feel can be mitigated by a
                    response, you can contact us and explain the circumstances,
                    so that we can consider whether it should be removed (but we
                    make no guarantee of this).
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingl23fifty">
                  <button
                    class="accordion-button collapsed"
                    style="font-weight: 400"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsel23fifty"
                    aria-expanded="false"
                    aria-controls="collapsel23fifty"
                  >
                    They have a new legal matter. Do I need to refer them back
                    to the platform to post a new job?
                  </button>
                </h2>
                <div
                  id="collapsel23fifty"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingl23fifty"
                  data-bs-parent="#accordionExample1"
                >
                  <div class="accordion-body">
                    No, you are under no obligation to do so. We are thrilled
                    that you were able to find a client and that they want to
                    maintain a relationship with you as their lawyer. However,
                    you are always welcome to encourage the client to post a new
                    job on the platform to find other lawyers if they need
                    independent legal advice or assistance in an area of law
                    that you do not cover.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- ---- -->
        <p
          class="LNNumberedHeading1 mt-4 mb-1"
          style="
            margin-left: 0cm;
            text-indent: 0cm;
            mso-pagination: none;
            page-break-after: auto;
            mso-list: none;
            tab-stops: 36pt;
          "
        >
          <span
            lang="EN-US"
            style="font-family: 'Montserrat', sans-serif; font-weight: 400"
          >
            <b>I have a question that isn’t listed here.</b>
          </span>
        </p>
        <p class="pb-5 n-mb">
          Please <router-link to="/contact-us">contact us</router-link> and let
          us know about your issue, and we will do our best to help.
        </p>
        <!-- ------- -->
      </div>
      <!-- ------- -->
      <!-- ----------     -->
    </div>
    <div class="footer footer-ct">
      <MainFooter />
    </div>
  </section>
</template>
<script>
import MainFooter from "../components/global/MainFooter.vue";
// import GeneralHeader from "./GeneralHeader.vue";
import LawyerHeader from "../pages/lawyer/Header.vue";

export default {
  components: {
    MainFooter,
    // GeneralHeader,
    LawyerHeader,
  },

  name: "FaqS",

  methods: {
    async hideButtonAndScroll() {
  await this.$nextTick();

  const accordionItems = document.querySelectorAll('.accordion-item');

  // Add event listener to each accordion item
  accordionItems.forEach(item => {
    item.addEventListener('click', () => {
      const targetElement = item.querySelector('#scToItem');

      // Check if target element exists and is within the clicked accordion item
      if (targetElement && item.contains(targetElement)) {
        // Scroll to bring the target element into the center of the viewport
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    });
  });
}

  },
};
</script>
<style scoped>
.LNNumberedHeading1 span {
  font-weight: 400 !important;
  font-size: 21px !important;
  color: black;
}
section.faq-main b {
  font-weight: 400;
}
.accordion-item {
  border: none !important;
  border-bottom: 1px solid #d1d1d1 !important;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  background-color: #000;
  color: #fff;
}

.accordion-button:not(.collapsed) span,
.accordion-button:not(.collapsed)::after {
  filter: brightness(0) invert(1);
}

.hd-b {
  margin-bottom: 10px;
}
.cstm-accor::after{
  content: unset;
}
.accordion-button {
  padding: 13px 10px !important;
  font-weight: 400 !important;
  font-size: 17px;
}

.nav.nav-tabs .nav-link.active {
  border: none;
  border-bottom: 5px solid #000;
}

.nav.nav-tabs .nav-link {
  border: none;
  border-bottom: 5px solid #e2e2e2;
}

.tab-title {
  margin-bottom: 6px;
  color: #000;
}

.accordion-body {
  /* font-weight: 400;; */
  font-size: 15px;
}

.footer-ct {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.faq-main {
  min-height: 100vh;
  position: relative;
}

.n-mb {
  margin-bottom: 0px !important;
  padding-bottom: 70px !important;
}

.cstm-accor {
  width: auto !important;
  display: inline-block;
  font-size: 15px;
  /* padding-left: 0 !important;
  padding-right: 0 !important; */
  font-weight: 400 !important;
  color: #000;
  text-decoration: underline;
  padding: 0 !important;
}

.accordion-button.cstm-accor:not(.collapsed) {
  background-color: transparent;
  color: #000;
  text-decoration: underline;
}

a,
a:hover {
  color: #000;
}
@media only screen and (max-width: 600px) {
  .n-mb {   
    padding-bottom: 90px !important;
}
  #lawyer-faq{
  padding-bottom: 30px;
}
}
</style>
